.marginTop4 {
    margin-top: 4rem;
}

.window {
    padding: 2rem 1rem 5rem 1rem;
    margin: 0rem;
}



.helpCard {
    background-color: rgb(224, 255, 221);
    border-radius: 1rem;
    padding: 1rem
}